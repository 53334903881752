import React, {useEffect, useState} from "react";
import "./Metric.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import MyDataHelps, {DeviceDataNamespace, DeviceDataPoint} from "@careevolution/mydatahelps-js";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import * as Model from "@careevolution/mydatahelps-js/types";
import { faAdjust } from "@fortawesome/free-solid-svg-icons";

const debounce = require('lodash.debounce');
const moment = require('moment');

export interface MetricProps {
    icon: IconProp;
    label: string;
    namespace: DeviceDataNamespace;
    type: string;
    onClick?: Function;
    fixLabel?: string;
    onFix?: Function;
    adjustment?: number;
}

export default function (props: MetricProps) {
    const [dataPoint, setDataPoint] = useState<DeviceDataPoint>();

    function initialize() {
        let params: Model.DeviceDataPointQuery = {
            namespace: props.namespace,
            type: props.type,
            observedAfter: moment().add(-14, "days").toDate(),
        };

        MyDataHelps.queryDeviceData(params).then(function (results) {
            let dataPoints = results.deviceDataPoints.filter(dp => dp.value !== "0");
            if (dataPoints.length > 0) {
                dataPoints.sort((a, b) => moment(b.observationDate).diff(moment(a.observationDate)));
                setDataPoint(dataPoints[0]);
            }
        });
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    function onClick() {
        if (props.onClick) {
            // props.onClick(); // temporary
        }
    }


    function onFix() {
        if (props.onFix) {
            props.onFix();
        }
    }

    if (!dataPoint) {
        return <div className="metric">
            <div>
                <div className="metric-label"><FontAwesomeIcon className="metric-icon" icon={props.icon}/>{props.label}</div>
                <div className="metric-value">No data</div>
            </div>
            {props.fixLabel && props.onFix &&
            <div className="metric-fix" onClick={() => onFix()}>{props.fixLabel}</div>
            }
        </div>;
    }

    let formattedDate = '';
    let observationDate = moment(dataPoint.observationDate);
    let observationDateDiff = moment().startOf('day').diff(moment(observationDate).startOf('day'), 'days');
    if (observationDateDiff > 1) {
        formattedDate = observationDate.format('M/D');
    } else if (observationDateDiff === 1) {
        formattedDate = 'Yesterday';
    } else {
        formattedDate = 'Today';
    }

    let value = dataPoint.value;
    let adjustment_factor = (props.adjustment) ? props.adjustment : 1;
    if (!Number.isNaN(value)) {
        value = (Number(value) * adjustment_factor).toLocaleString();
    }

    return <div className="metric" onClick={() => onClick()}>
        <div>
            <div className="metric-label"><FontAwesomeIcon className="metric-icon" icon={props.icon}/>{props.label}</div>
            <div className="metric-value">{value}{dataPoint.units}</div>
        </div>
        <div className="metric-date">{formattedDate}</div>
        <div className="metric-drill"><FontAwesomeIcon icon={faChevronRight}/></div>
    </div>;
}