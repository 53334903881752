import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { Button, ConnectEhr, LoadingIndicator, SurveyTaskList } from '@careevolution/mydatahelps-ui';
import MyDataHelps, { DeviceDataPoint, ExternalAccount, SurveyTask } from '@careevolution/mydatahelps-js';
import Metric from "../components/Metric";
import { faLungs, faPersonRunning, faSmog } from "@fortawesome/free-solid-svg-icons";
import Mood from "../components/Mood";
import * as Model from "@careevolution/mydatahelps-js/types";
import { Moment } from "moment";


const debounce = require('lodash.debounce');
const moment = require('moment');

export type ClearAlertType = undefined | 'SpO2' | 'Multiple';

export default function () {
    const [loadingParticipantInfo, setLoadingParticipantInfo] = useState<boolean>(true);
    const [loadingDeviceDataPoints, setLoadingDeviceDataPoints] = useState<boolean>(true);
    const [loadingIncompleteTasks, setLoadingIncompleteTasks] = useState<boolean>(true);
    const [loadingExternalAccounts, setLoadingExternalAccounts] = useState<boolean>(true);
    const [loadingSurveyAnswers, setLoadingSurveyAnswers] = useState<boolean>(true);

    const [participantInfo, setParticipantInfo] = useState<any>();
    const [wellinksEnabled, setWellinksEnabled] = useState<boolean>();
    const [welcomeKitShipped, setWelcomeKitShipped] = useState<boolean>();
    const [welcomeCallCompleted, setWelcomeCallCompleted] = useState<boolean>();
    const [kitStatus, setKitStatus] = useState<DeviceDataPoint>();
    const [markingKitReceived, setMarkingKitReceived] = useState<boolean>();
    const [clearResponsesAlert, setClearResponsesAlert] = useState<DeviceDataPoint>();
    const [callingMIH, setCallingMIH] = useState<boolean>();
    const [calledMIH, setCalledMIH] = useState<boolean>();
    const [spo2Alert, setSpo2Alert] = useState<DeviceDataPoint>();
    const [lastClearDate, setLastClearDate] = useState<Date>();
    const [incompleteTasks, setIncompleteTasks] = useState<SurveyTask[]>();
    const [fitbitAccount, setFitbitAccount] = useState<ExternalAccount>();
    const [blueButtonAccount, setBlueButtonAccount] = useState<ExternalAccount>();
    const [participantCallStatus, setParticipantCallStatus] = useState<DeviceDataPoint>();
    const [markingCallBooked, setMarkingCallBooked] = useState<boolean>();
    const [callBooked, setCallBooked] = useState<boolean>();
    const [mostRecentSpO2Date, setMostRecentSpO2Date] = useState<Date>();
    const [studyPhase, setStudyPhase] = useState<any>();
    const [reportedResult, setReportedResult] = useState<DeviceDataPoint>();
    const [recordedTest, setRecordedTest] = useState<any>();
    const [covidOrderNumber, setCovidOrderNumber] = useState<any>();
    const [appointmentDate, setAppointmentDate] = useState<any>();
    const [appointmentTime, setAppointmentTime] = useState<any>();
    const [hasLowOxygen, setHasLowOxygen] = useState<boolean>();

    function loadParticipantInfo() {
        MyDataHelps.getParticipantInfo().then(function (participantInfo) {
            setParticipantInfo(participantInfo);

            let customFields = participantInfo.customFields;
            setWellinksEnabled(customFields.hasOwnProperty('ThirdPartyStatus') && customFields['ThirdPartyStatus'].trim().length > 0);
            setWelcomeKitShipped(customFields.hasOwnProperty('WelcomeKitShipped') && customFields['WelcomeKitShipped'] === 'Shipped');
            setWelcomeCallCompleted(customFields.hasOwnProperty('WelcomeCallCompleted') && customFields['WelcomeCallCompleted'] === 'True');
            setCallBooked(customFields.hasOwnProperty('CallStatus') && customFields['CallStatus'].trim().length > 0);
            setMostRecentSpO2Date(customFields.hasOwnProperty('MostRecentSPO2Date') ? moment(customFields['MostRecentSPO2Date']) : null);
            setStudyPhase(customFields.hasOwnProperty('StudyPhase') ? customFields['StudyPhase'] : "Onboarding");
            setCalledMIH(customFields.hasOwnProperty('CalledMIH') ? customFields['CalledMIH'].trim().length > 0 : false);
            setRecordedTest(customFields.hasOwnProperty('RecordedTest') ? customFields['RecordedTest'].trim().length > 0 : false);
            setCovidOrderNumber(customFields.hasOwnProperty('KitOrderId') && customFields['KitOrderId'].trim().length > 0 ? customFields['KitOrderId'] : "pending");
            setAppointmentDate(customFields.hasOwnProperty('AppointmentDate') && customFields['AppointmentDate'].trim().length > 0 ? customFields['AppointmentDate'] : "Today");
            setAppointmentTime(customFields.hasOwnProperty('AppointmentTime') && customFields['AppointmentTime'].trim().length > 0 ? customFields['AppointmentTime'] : "noon");


            setLoadingParticipantInfo(false);
        });
    }

    function loadDeviceDataPoints(callback?: Function) {
        let params: Model.DeviceDataPointQuery = {
            namespace: 'Project',
            type: ['KitStatus', 'ParticipantCallStatus', 'Alert', 'ReportedResults']
        };

        let getAlertDate = function (alertDataPoint: DeviceDataPoint) {
            if (alertDataPoint && alertDataPoint.properties && alertDataPoint.properties.hasOwnProperty('date')) {
                return moment(alertDataPoint.properties['date']);
            }
            return null;
        };

        MyDataHelps.queryDeviceData(params).then(function (results) {
            let dataPoints = results.deviceDataPoints;

            setKitStatus(dataPoints.find(dp => dp.type === 'KitStatus'));
            //setParticipantCallStatus(dataPoints.find(dp => dp.type === 'ParticipantCallStatus'));
            setReportedResult(dataPoints.find(dp => dp.type === 'ReportedResults'));

            let alerts = dataPoints.filter(dp => dp.type === 'Alert');
            let groupedAlerts = alerts.reduce(function (map: Record<string, DeviceDataPoint[]>, dp) {
                if (dp.properties && dp.properties.hasOwnProperty('source')) {
                    let key = dp.properties['source'];
                    (map[key] = map[key] || []).push(dp);
                }
                return map;
            }, {});

            if (groupedAlerts.hasOwnProperty('SpO2')) {
                let spo2Alerts = groupedAlerts['SpO2'];
                spo2Alerts.sort((a, b) => moment(getAlertDate(b)).diff(moment(getAlertDate(a))));
                setSpo2Alert(spo2Alerts[0]);
            }

            if (groupedAlerts.hasOwnProperty('ClearResponses')) {
                let clearResponsesAlerts = groupedAlerts['ClearResponses'];
                clearResponsesAlerts.sort((a, b) => moment(getAlertDate(b)).diff(moment(getAlertDate(a))));
                setClearResponsesAlert(clearResponsesAlerts[0]);
            }

            setLoadingDeviceDataPoints(false);

            if (callback) {
                callback();
            }
        });
    }

    function loadIncompleteTasks() {
        let params: Model.SurveyTaskQueryParameters = {
            status: 'incomplete'
        };

        MyDataHelps.querySurveyTasks(params).then(function (result) {
            setIncompleteTasks(result.surveyTasks);
            setLoadingIncompleteTasks(false);
        });
    }

    function isDevelopment() {
        return !MyDataHelps.baseUrl || MyDataHelps.baseUrl.startsWith("https://mdhorg.ce.dev") || MyDataHelps.baseUrl.startsWith("https://mydatahelps.dev");
    }

    function getFitbitProviderId() {
        return isDevelopment() ? 2 : 564;
    }

    function getBlueButtonProviderId() {
        return isDevelopment() ? 17 : 430;
    }

    function loadExternalAccounts() {
        MyDataHelps.getExternalAccounts().then(function (externalAccounts) {
            let fitbitProviderId = getFitbitProviderId();
            let blueButtonProviderId = getBlueButtonProviderId();

            let foundFitbitAccount = false;
            let foundBlueButtonAccount = false;
            for (let i = 0; i < externalAccounts.length; i++) {
                if (externalAccounts[i].provider.id === fitbitProviderId) {
                    setFitbitAccount(externalAccounts[i]);
                    foundFitbitAccount = true;
                } else if (externalAccounts[i].provider.id === blueButtonProviderId) {
                    setBlueButtonAccount(externalAccounts[i]);
                    foundBlueButtonAccount = true;
                }
            }

            if (!foundFitbitAccount) {
                setFitbitAccount(undefined);
            }

            if (!foundBlueButtonAccount) {
                setBlueButtonAccount(undefined);
            }

            setLoadingExternalAccounts(false);
        });
    }

    function loadSurveyAnswers() {
        let params: Model.SurveyAnswersQuery = {
            surveyName: ['Clear']
        };

        MyDataHelps.querySurveyAnswers(params).then(function (result) {
            let surveyAnswers = result.surveyAnswers;

            if (surveyAnswers.length > 0) {
                let clearAnswers = surveyAnswers.filter(a => a.surveyName === 'Clear');
                if (clearAnswers.length > 0) {
                    clearAnswers.sort((a, b) => moment(b.date).diff(moment(a.date)));
                    setLastClearDate(moment(clearAnswers[0].date));
                }
            }

            setLoadingSurveyAnswers(false);
        });
    }

    function querySpO2Data() {
        let params: Model.DeviceDataPointQuery = {
            namespace: "AppleHealth",
            type: "OxygenSaturation",
            observedAfter: moment().add(-1, "hours").toDate()
        };

        MyDataHelps.queryDeviceData(params).then(function (results) {
            let dataPoints = results.deviceDataPoints.filter(dp => dp.value !== "0");
            if (dataPoints.length > 0) {
                dataPoints.sort((a, b) => moment(b.observationDate).diff(moment(a.observationDate)));
                setHasLowOxygen(Number(dataPoints[0].value) < 0.9);
            }
        });
    }

    function initialize() {
        loadParticipantInfo();
        loadDeviceDataPoints();
        loadIncompleteTasks();
        loadExternalAccounts();
        loadSurveyAnswers();
        querySpO2Data();
    }

    function onEnterSymptoms() {
        MyDataHelps.startSurvey('Clear');
    }

    function onBookNow() {
        MyDataHelps.persistDeviceData([{ identifier: 'ParticipantCallStatus', type: 'ParticipantCallStatus', value: 'booked' }]).then(function () {
            loadDeviceDataPoints();
        });
        MyDataHelps.openEmbeddedUrl('https://outlook.office365.com/owa/calendar/HIMSSConnectingwithPatientsatHome@umassmed.edu/bookings/');
    }

    function reportTestResult() {
        MyDataHelps.persistDeviceData([{ identifier: 'ReportedResults', type: 'ReportedResults', value: 'reported' }]).then(function () {
            loadDeviceDataPoints();
        });
        MyDataHelps.openEmbeddedUrl('https://makemytestcount.careevolutionapps.dev/#Step01');
    }
    function onTestReport() {
        MyDataHelps.startSurvey('CovidTestResult');
    }

    function onConnectFitbit() {
        MyDataHelps.connectExternalAccount(getFitbitProviderId());
    }

    function onKitReceived() {
        setMarkingKitReceived(true);
        MyDataHelps.persistDeviceData([{ identifier: 'KitStatus', type: 'KitStatus', value: 'received' }]).then(function () {
            loadDeviceDataPoints(() => setMarkingKitReceived(false));
        });
        MyDataHelps.startSurvey('KitReceived');

    }

    function onCallBooked() {
        setMarkingCallBooked(true);
        MyDataHelps.persistDeviceData([{ identifier: 'ParticipantCallStatus', type: 'ParticipantCallStatus', value: 'booked' }]).then(function () {
            loadDeviceDataPoints(() => setMarkingCallBooked(false));
        });
    }

    function onWellinks() {
        MyDataHelps.startSurvey('Wellinks');
    }

    function onGetHelp() {
        MyDataHelps.openApplication(new URL('help', document.location as any).href, {modal: true});
    }

    function onCallMIH() {
        MyDataHelps.startSurvey('CallMIH');

    }

    function onSteps() {
        MyDataHelps.openApplication(new URL('steps', document.location as any).href);
    }

    function onEnterMood() {
        MyDataHelps.startSurvey('Single Item Wellness');
    }

    function onConnectBlueButton() {
        MyDataHelps.connectExternalAccount(getBlueButtonProviderId());
    }

    function hasCompletedClearSurveyAfter(dateToCompareTo: Moment) {
        return lastClearDate && moment(lastClearDate).isAfter(dateToCompareTo);
    }

    function hasReceivedKit() {
        return kitStatus && kitStatus.value === 'received';
    }

    function hasBookedCall() {
        return callBooked;
    }

    function hasReportedTest() {
        return (reportedResult && reportedResult.value === 'reported');
    }

    useEffect(() => {
        let debouncedInitialize = debounce(initialize, 500);

        debouncedInitialize();
        MyDataHelps.on('applicationDidBecomeVisible', debouncedInitialize);
        return () => {
            MyDataHelps.off('applicationDidBecomeVisible', debouncedInitialize);
        }
    }, []);

    if (loadingParticipantInfo || loadingDeviceDataPoints || loadingIncompleteTasks || loadingExternalAccounts || loadingSurveyAnswers) {
        return null;
    }

    let enrollmentDate = moment(participantInfo.enrollmentDate);
    let daysInStudy = moment().diff(enrollmentDate, 'days') + 1;

    let clearAlert: ClearAlertType;
    if (spo2Alert
        && spo2Alert.properties
        && spo2Alert.properties.hasOwnProperty('date')
        && !hasCompletedClearSurveyAfter(moment(spo2Alert.properties['date']).startOf('day'))
        && (!mostRecentSpO2Date || !moment(spo2Alert.properties['date']).isBefore(mostRecentSpO2Date))) {
        clearAlert = 'SpO2';
    }

    let mihAlert: boolean = false;
    if (clearResponsesAlert
        && clearResponsesAlert.properties
        && clearResponsesAlert.properties.hasOwnProperty('date')
        && !hasCompletedClearSurveyAfter(moment(clearResponsesAlert.properties['date']))
        && moment(clearResponsesAlert.properties['date']).isAfter(moment().add(-3, 'days'))
        && (!clearResponsesAlert.properties.hasOwnProperty('status') || clearResponsesAlert.properties['status'] !== 'clicked')) {
        mihAlert = true;
    }

    function onCallSupport() {
        MyDataHelps.openExternalUrl("tel:5085231421");
    }

    function onEmailSupport() {
        MyDataHelps.openExternalUrl("mailto:healthyathome@umassmed.edu");
    }

    return (
        <div className="dashboard">
            <div className="header">
                <div className="logo"><img src="/images/logo.svg" alt="logo" /></div>
                {studyPhase == "Onboarding" &&
                    <div>
                        {hasBookedCall()
                            ? <div>
                                {!hasReceivedKit() ?
                                    <div className="cta">
                                        <div className="cta-title">Congratulations!</div>
                                        <div className="cta-text">
                                            <p>You are officially enrolled and should expect your Welcome kit within 3-5 business days.</p>
                                            <p>We have ordered you materials that are needed to complete the study, including informational materials, a Fitbit device, a digital spirometer, and an at-home covid test. For reference, your order number is {covidOrderNumber}. </p>
                                            <p>Your introduction call with the study team is on {appointmentDate} at {appointmentTime}. </p>
                                        </div>
                                        {markingKitReceived
                                            ? <LoadingIndicator />
                                            : <Button children="I've received my kit" onClick={() => onKitReceived()} />
                                        }
                                        {incompleteTasks && incompleteTasks.length > 0 &&
                                            <div className="cta-text">While you're waiting, please complete your surveys.</div>
                                        }
                                    </div>
                                    : <div className="cta">
                                        <div className="cta-title">Thank you for participating!</div>
                                        {wellinksEnabled
                                            ? <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys.</div>
                                            : <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys. Also, you may opt in to Wellinks below.</div>
                                        }
                                    </div>
                                }
                            </div>
                            : <div className="cta">
                                <div className="cta-title">Let's schedule your intro call!</div>
                                <div className="cta-text">Please let us know your preference for when to schedule an intro call.</div>
                                {markingCallBooked
                                    ? <LoadingIndicator />
                                    : <div>
                                        <Button children="Book Now" onClick={() => onBookNow()} />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
                {studyPhase == "Call" &&
                    <div className="cta">
                    <div className="cta-title">Thank you for participating!</div>
                        <div className="cta-text">
                        {wellinksEnabled
                            ? <p>Please continue to wear your Fitbit and complete any outstanding surveys.</p>
                            : <p>Please continue to wear your Fitbit and complete any outstanding surveys. Also, you may opt in to Wellinks below.</p>
                        }
                        <p>Your introduction call with the study team is today at {appointmentTime}. </p>
                        </div>
                    </div>
                }

                { (studyPhase == "Covid" && !hasLowOxygen) &&
                <div>
                    {calledMIH ? 
                        <>
                            { hasReportedTest()  ?
                                <div className="cta">
                                    <div className="cta-title">Thank you for participating!</div>
                                    {wellinksEnabled
                                        ? <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys.</div>
                                        : <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys. Also, you may opt in to Wellinks below.</div>
                                    }
                                </div>

                                :
                                //recordTest
                                <>
                                    {recordedTest ?
                                        //Report Results
                                        <div className="cta">
                                            <div className="cta-title">Submit your test result to Public Health</div>
                                            <div className="cta-text">
                                                Reporting your result to public health will help keep your community safe.
                                            </div>
                                            <div>
                                                <Button children="Report Test Result" onClick={() => reportTestResult()} />
                                            </div>
                                        </div>
                                        :
                                        <div className="cta">
                                            <div className="cta-title">Please take a COVID Test!</div>
                                            <div className="cta-text">
                                                <p>You may use the test we shipped at the beginning of the study, or any other rapid antigen test you may have.</p>
                                                <p>Please record the result here.</p>
                                            </div>
                                            <Button children="Record Test Result" onClick={() => onTestReport()} />
                                        </div>
                                    }
                                </>

                            }
                        </>
                        :
                        //Call MIH
                        <div>
                            <div className="cta-title">Symptom Alert</div>
                            <div className="cta-text">Please contact the MIH for follow up regarding your symptoms.</div>
                            {callingMIH
                                ? <LoadingIndicator />
                                : <Button children="Contact the MIH" onClick={() => onCallMIH()} />
                            }
                        </div>  
                    }                
                </div>
                }
                { (studyPhase == "Alert" || hasLowOxygen ) &&
                    <div className="cta">
                        <div className="cta-title">Your oxygen saturation is <span className="warning">low</span>.</div>
                        <div className="cta-text">Please check in with us on how you're doing:</div>
                        <Button children="Complete Symptom Survey" onClick={() => onEnterSymptoms()} />
                    </div>
                }
                {/* <div className="cta-text">
                    <p>studyPhase: {studyPhase}</p>
                    <p>calledMIH: {calledMIH}</p>
                    <p>recordedTest: {recordedTest}</p>
                    <p>reportedResult: {reportedResult ? reportedResult.value : ""}</p>
                </div> */}



                {/* {hasReceivedKit()
                    ? <div>
                        {hasBookedCall()
                            ? fitbitAccount
                                ? mihAlert || clearAlert
                                    ? mihAlert
                                        ? <div>
                                            <div className="cta-title">Symptom Alert</div>
                                            <div className="cta-text">Please contact the MIH for follow up regarding your symptoms.</div>
                                            {callingMIH
                                                ? <LoadingIndicator />
                                                : <Button children="Contact the MIH" onClick={() => onCallMIH()} />
                                            }
                                        </div>
                                        : <div className="cta">
                                            {clearAlert === 'SpO2'
                                                ? <div className="cta-title">Your oxygen saturation is <span className="warning">low</span>.</div>
                                                : <div className="cta-title">One of your biometrics is out of range.</div>
                                            }
                                            <div className="cta-text">Please check in with us on how you're doing:</div>
                                            <Button children="Complete Symptom Survey" onClick={() => onEnterSymptoms()} />
                                        </div>
                                    : <div className="cta">
                                        <div className="cta-title">Thank you for participating!</div>
                                        {wellinksEnabled
                                            ? <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys.</div>
                                            : <div className="cta-text">Please continue to wear your Fitbit and complete any outstanding surveys. Also, you may opt in to Wellinks below.</div>
                                        }
                                    </div>
                                : <div className="cta">
                                    <div className="cta-title">Explore your test kit!</div>
                                    {welcomeCallCompleted
                                        ? <div className="cta-text">You may now set up and connect your Fitbit, or we can help you connect it on the phone.</div>
                                        : <div className="cta-text">
                                            <p>We'll give you a call in the next few days during your preferred call window to complete your enrollment.</p>
                                            <p>If you would like to try to set up your Fitbit, you may follow the instructions in the packet included in the welcome kit.</p>
                                            <p>Otherwise, we will walk you through setting up the device on our call.</p>
                                        </div>
                                    }
                                    <Button children="Connect Fitbit" onClick={() => onConnectFitbit()} />
                                </div>
                            : <div className="cta">
                                <div className="cta-title">Let's schedule your intro call!</div>
                                <div className="cta-text">Please let us know your preference for when to schedule an intro call.</div>
                                {markingCallBooked
                                    ? <LoadingIndicator />
                                    : <div>
                                        <Button children="Book Now" onClick={() => onBookNow()} />
                                        <div className="small-link center" onClick={() => onCallBooked()}>I have already booked a time.</div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    : <div className="cta">
                        <div className="cta-title">Congratulations!</div>
                        <div className="cta-text">
                            <p>You are officially enrolled and should expect your Welcome kit within 3-5 business days.</p>
                            <p>We'll be sending you materials that you need to complete the study, including informational materials, a Fitbit device, and instructions about how to get started with the study.</p>
                            <p>Let us know when you get your kit and we'll schedule your welcome call.</p>
                        </div>
                        {markingKitReceived
                            ? <LoadingIndicator />
                            : <Button disabled={!welcomeKitShipped} children="I've received my kit" onClick={() => onKitReceived()} />
                        }
                        {incompleteTasks && incompleteTasks.length > 0 &&
                            <div className="cta-text">While you're waiting, please complete your surveys.</div>
                        }
                    </div>
                } */}
            </div>
            <div className="content">
                <div className="cta-title">Check-In</div>
                <div className="check-in">
                    <Mood onClick={() => onEnterMood()} />
                    {!clearAlert &&
                        <Button children="Log Symptoms" onClick={() => onEnterSymptoms()} />
                    }
                </div>
                <div className="cta-title">I'm not feeling well</div>
                <div className="get-help">
                    <Button children="Get Help" onClick={() => onGetHelp()} />
                </div>
                {incompleteTasks && incompleteTasks.length > 0 &&
                    <div>
                        <div className="cta-title">Surveys</div>
                        <div className="cta-group">
                            <div className="tasks">
                                <SurveyTaskList status="incomplete" />
                            </div>
                        </div>
                    </div>
                }
                {!wellinksEnabled && daysInStudy <= 90 &&
                    <div>
                        <div className="cta-title">Enroll in Wellinks</div>
                        <div className="cta-group wellinks">
                            <div className="cta-text">As a part of the study you can choose to opt into Wellinks, in which you can receive private health coaching and at-home medical devices, at no cost to you.</div>
                            <Button children="Learn more and enroll" onClick={() => onWellinks()} />
                            <div className="cta-text-small center">You can opt into Wellinks anytime in your first 90 days. You have {91 - daysInStudy} days remaining to enroll.</div>
                        </div>
                    </div>
                }
                <div className="cta-title">My Health @ Home</div>
                <div className="cta-group metrics">
                    {fitbitAccount || !hasReceivedKit()
                        ? <Metric icon={faLungs} label="Oxygen saturation" namespace="Fitbit" type="SpO2" />
                        : <Metric icon={faLungs} label="Oxygen saturation" namespace="Fitbit" type="SpO2" fixLabel="Connect Fitbit" onFix={() => onConnectFitbit()} />
                    }
                    {fitbitAccount || !hasReceivedKit()
                        ? <Metric icon={faPersonRunning} label="Daily steps" namespace="Fitbit" type="Steps" onClick={() => onSteps()} />
                        : <Metric icon={faPersonRunning} label="Daily steps" namespace="Fitbit" type="Steps" fixLabel="Connect Fitbit" onFix={() => onConnectFitbit()} />
                    }
                    {!hasReceivedKit()
                        ? <Metric icon={faLungs} label="Oxygen saturation (AH)" namespace="AppleHealth" type="OxygenSaturation" adjustment={100} />
                        : <Metric icon={faLungs} label="Oxygen saturation (AH)" namespace="AppleHealth" type="OxygenSaturation" adjustment={100}/>
                    }
                    <Metric icon={faSmog} label="Air quality" namespace="AirNowApi" type="AqiAirQuality" />
                </div>
                <div className="cta-title">Data Sharing</div>
                <div className="cta-group data-sharing">
                    <ConnectEhr applicationUrl="https://viewlibrary.careevolutionapps.com/connectehr?presentation=Push" previewState="enabled" />
                </div>
                {!blueButtonAccount &&
                    <div className="cta-group blue-button">
                        <div className="cta-text">Connect to CMS Blue Button to share your Medicare claims data.</div>
                        <Button children="Connect Blue Button" onClick={() => onConnectBlueButton()} />
                    </div>
                }
                <div className="cta-title">Study Support</div>
                <div className="cta-group support">
                    <div className="cta-text-small">If you are feeling OK but have a question about the study or the app, please email us at healthyathome@umassmed.edu or call us at (508) 523-1421.</div>
                    <Button children="Send Email" onClick={() => onEmailSupport()} />
                    <Button children="Call: 508-523-1421" onClick={() => onCallSupport()} />
                    <div className="cta-text-small">The information below may be helpful when contacting the study team for support.</div>
                    <div className="support-info">
                        <div className="support-info-label">Participant Identifier</div>
                        <div className="support-info-value">{participantInfo.participantIdentifier}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
